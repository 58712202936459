.pagination {
  &-v1 {
    display: flex;
    justify-content: center;
    align-items: center;
    span,
    a {
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      //   background-color: theme("colors.white");
      //   border: 2px solid theme("colors.brand_02.dark");
      //   color: theme("colors.brand_02.dark");
      @extend .title-sm;
      margin: 0.5rem;
      transition: 0.5s;
      text-decoration: none;

      @apply border-gray border text-gray;

      path {
        transition: 0.5s;
      }

      &.current {
        @apply border-brand_01 border text-brand_01;
      }

      &:hover {
        // @apply border-gray border text-gray;

        &.page-numbers {
          @apply bg-brand_01 text-white border-brand_01;
        }

        &.current {
          @apply bg-transparent text-brand_01;
        }
      }
      &.next,
      &.prev {
        @apply text-brand_01;
        border: 0px;

        &:hover {
          @apply bg-transparent;
        }
      }
    }
  }
}
