/**
 * Basic typography style for copy text
*/

@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;700&display=swap");

// body {
//   font-family: "Hind", sans-serif;
//   font-style: normal;
//   color: theme("colors.black.default");
//   // -moz-osx-font-smoothing: grayscale;
// }

ul,
ol {
  list-style: initial;
  padding-left: 2rem;
}
ol {
  list-style: decimal;
}

main p {
  margin: 1.5rem 0;
}

.title {
  &-xl,
  &-xl * {
    font-family: Hind;
    font-size: 40px;
    font-style: normal;
    line-height: 45px;
    letter-spacing: 0em;
    font-weight: 400;
    b,
    strong,
    span {
      @apply text-brand_01;
      font-weight: 700;
    }
    // @screen lg {
    //   font-size: 5.8rem;
    //   line-height: 7rem;
    // }
  }
  &-lg,
  &-lg * {
    font-family: Hind;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    b,
    strong,
    span {
      @apply text-brand_01;
      font-weight: 700;
    }
    // @screen lg {
    //   font-size: 4rem;
    //   line-height: 4.8rem;
    // }
  }
  &-md,
  &-md * {
    font-family: Hind;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    b,
    strong,
    span {
      @apply text-brand_01;
      font-weight: 700;
    }
    // @screen lg {
    //   font-size: 3.5rem;
    //   line-height: 4.5rem;
    // }
  }
  &-sm,
  &-sm * {
    font-family: Hind;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    b,
    strong,
    span {
      @apply text-brand_01;
      font-weight: 700;
    }
    // @screen lg {
    //   font-size: 2.4rem;
    //   line-height: 3.8rem;
    // }
  }

  &-xs,
  &-xs * {
    font-family: Hind;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    // @screen lg {
    //   font-size: 2.4rem;
    //   line-height: 3.8rem;
    // }
  }
}

.text {
  &-lg,
  &-lg * {
    font-family: Hind;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.1em;
    b,
    strong {
      font-weight: 700;
    }
    a {
      font-weight: 700;
      text-decoration: underline;
    }
    // @screen lg {
    //   font-size: 2.8rem;
    //   line-height: 4.5rem;
    // }
  }
  &-md,
  &-md * {
    font-family: Hind;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1em;
    b,
    strong {
      font-weight: 700;
    }
    a {
      font-weight: 700;
      text-decoration: underline;
    }
    // @screen lg {
    //   font-size: 1.8rem;
    //   line-height: 3rem;
    // }
  }
  &-sm,
  &-sm * {
    font-family: Hind;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1em;
    b,
    strong {
      font-weight: 700;
    }
    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
  &-button {
    // @extend .text-button-m;
    &-l {
    }
    &-m {
    }
    &-s {
    }
  }
}

.link {
  @extend .title-sm;
  @apply text-brand_01-dark;
  transition: 0.5s;
  text-decoration: none;
  &:hover {
    @apply text-brand_01;
  }
}

// .link {
//   &-l {
//     font-family: Montserrat;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 2rem;
//     letter-spacing: 0.1em;
//     text-decoration-line: underline;
//   }
//   &-m {
//     font-family: Montserrat;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 1.4rem;
//     letter-spacing: 0.1em;
//     text-decoration-line: underline;
//   }

//   &-s {
//     font-family: Montserrat;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 1.2rem;
//     letter-spacing: 0.1em;
//     text-decoration-line: underline;
//   }
// }

.caption {
}

blockquote,
.blockquote p {
  @extend .text-lg;
  font-weight: bold;
}

.label {
}

.text {
}

@layer base {
  body,
  p {
    @extend .text-md;
  }
  body h1 {
    @extend .title-xl;
  }
  body h2 {
    @extend .title-lg;
  }
  body h3 {
    @extend .title-md;
  }
  body h4 {
    @extend .title-sm;
  }
  body h5 {
    @extend .title-xs;
  }
  body h6 {
    @extend .title-xs;
  }

  body blockquote {
    @extend .text-lg;
  }
}

.single-post__content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    b,
    strong {
      @apply text-brand_01-dark;
    }
  }
}

p,
ul,
li {
  // color: theme("colors.black.light");
  // &.has-xxl-font-size {
  //   @extend .text-l;
  // }
  // &.has-xl-font-size {
  //   @extend .text-l;
  // }
  // &.has-lg-font-size {
  //   @extend .text-l;
  // }
  // &.has-md-font-size {
  //   @extend .text-m;
  // }
  // &.has-sm-font-size {
  //   @extend .text-s;
  // }
  // &.has-xs-font-size {
  //   @extend .text-sm;
  // }
  // &.has-xxs-font-size {
  //   @extend .text-sm;
  // }
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   // color: theme("colors.black.default");
//   // &.has-xxl-font-size {
//   //   @extend .title-xl;
//   // }
//   // &.has-xl-font-size {
//   //   @extend .title-xl;
//   // }
//   // &.has-lg-font-size {
//   //   @extend .title-l;
//   // }
//   // &.has-md-font-size {
//   //   @extend .title-m;
//   // }
//   // &.has-sm-font-size {
//   //   @extend .title-s;
//   // }
//   // &.has-xs-font-size {
//   //   @extend .title-sm;
//   // }
//   // &.has-xxs-font-size {
//   //   @extend .title-sm;
//   // }
// }

.text-white {
  p,
  ul,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}
