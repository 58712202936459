.slider--v2 {
  .swiper-slide {
    width: auto !important;

    img {
    }
  }

  .swiper-button-disabled {
    // display: none;
  }

  .swiper-button-prev {
    margin-right: -2px;
  }
}
