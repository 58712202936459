.footer {
  &--v1 {
    position: relative;
    .title-m {
      display: inline-block;
      margin-bottom: 1rem;
      &::after {
        content: "";
        width: 100%;
        height: 0.3rem;
        display: block;
        border-radius: 2rem;
        margin-top: 0.3rem;
      }
      &.col-orange::after {
        background-color: theme("colors.orange.default");
      }
      &.col-green::after {
        background-color: theme("colors.green.default");
      }
      &.col-red::after {
        background-color: theme("colors.red.default");
      }
      &.col-yellow::after {
        background-color: theme("colors.yellow.default");
      }
      &.col-purple::after {
        background-color: theme("colors.purple.default");
      }
      &.col-greenwater::after {
        background-color: theme("colors.greenwater.default");
      }
    }
    img {
      width: 25rem;
      margin: 1rem auto 2rem;
      @screen md {
        margin: 1rem 0 2rem -1.5rem;
      }
    }
    h3 {
      @extend .title-sm;
      @apply mb-sm;
    }
    p,
    a {
      @extend .text-sm;
    }
    p a {
      font-weight: 500;
      color: theme("colors.ui.6");
    }
    ul {
      float: left;
      li {
        @apply mt-sm;
        a {
          opacity: 0.7;
          transition: 0.5s;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .shape-footer-down {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: block;
      margin: 0;
      z-index: -1;
      transform: scale(2.5);
      transform-origin: 50% 100%;
      @screen md {
        transform: scale(1);
      }
    }
    .menu-lingue {
      position: relative;
      top: 0;
      left: 0;
    }
  }
  &__instagram {
    padding: 10rem 0;
    position: relative;
    .parallax {
      position: relative;
      // top: 0;
      // left: 0;
      width: 100%;
      // height: 100%;
      opacity: 0.5;
      z-index: -1;
      .rellax {
        width: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
        img {
          width: 100%;
        }
        &:nth-child(1) {
          top: 0rem;
          // top: -33rem;
        }
        &:nth-child(2) {
          top: 20rem;
          // top: -25rem;
        }
      }
    }
    #sb_instagram #sbi_images {
      .sbi_item {
        @apply w-1/2;
        @screen md {
          @apply w-1/5;
        }
        &:last-child {
          display: none;
          @screen md {
            display: block;
          }
        }
      }
    }
  }
  &__partners {
    position: relative;
  }
}
