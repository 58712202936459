.map {
  &--v1 {
    &.map {
      iframe {
        filter: grayscale(100%);

        height: 70rem;
      }

      .map__items {
        & > div {
          @apply py-xs;
        }

        .map__emails {
          & > div {
            @apply py-xs;
          }
        }
      }
    }
  }
}
