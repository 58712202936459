.button {
  &-v3 {
    @apply bg-transparent border border-transparent;
    transition: 0.5s;
    text-decoration: none;
    @apply border-black;

    .button__icon {
      margin: 1rem;
    }

    strong,
    b {
      @apply text-black;
    }

    a {
      text-decoration: none;
    }
    &--md {
      @extend .title-sm;
      @apply px-lg py-sm;
    }
    &:hover {
      @apply bg-white border-white;
    }
  }
}
