.tab-gallery-v1 {
  .tab-gallery-inner {
    &__disable {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }
  }

  .tab-gallery__info {
    @apply bg-white;
    transition: opacity 0.7s;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 1rem;
    padding: 1rem;
    width: calc(100% - 2rem);
    p {
      margin: 5px 0;
    }

    span {
    }
  }

  .gallery__item {
    &:hover {
      .tab-gallery__info {
        opacity: 1;
      }
    }
  }
}
