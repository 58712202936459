.o-files {
  img {
    max-width: 62px;
    margin: 0 auto;
  }

  a.text-md.rounded-md.block.no-underline {
    transition: opacity 0.7s;
    &:hover {
      opacity: 0.4;
    }
  }
}

.tab-container-files {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  gap: 1rem;

  .button-v2 {
    grid-row-start: 1;
  }

  @media screen and(max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);

    .button-v2 {
      grid-row-start: auto;
    }
  }

  @media screen and(max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);

    .button-v2 {
      grid-row-start: auto;
    }
  }

  @media screen and(max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
