

h3.widget-title,
.wp-block-search__label {
    @extend .title-sm;
    @apply mb-sm;
    display: block;
}

.sidebar {
    section {
        @apply mb-lg;
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            a {
                text-decoration: none;
                display: block;
                @apply my-sm;
                transition: .5s;
                &:hover {
                    @apply text-brand_01;
                }
            }
            &.current-cat {
                a {
                    @apply text-brand_01;
                }
            }
        }
    }
}

.wp-block-search {
   &__input {
       border-radius: 0px;
       border-color: theme('colors.black.default');
   }
   &__button  {
       @extend .button-v1;
       @extend .button-v1--md;
       border: 0px;
   }
}
