.header-space {
  height: 120px;
}

.header-v1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  transition: background-color 0.2s, padding 0.4s;

  .logo img {
    width: 200px;
    transition: 0.5s;
  }

  @media screen and (max-width: 767px) {
    padding: 1rem;
  }

  .header-mobile__trigger {
    height: 53px;
    padding: 0 1rem;
    transition: all 0.5s;

    &--white {
      background-color: white;
    }
  }

  .header__controls {
    z-index: 1000;
  }

  .header__contact {
    transition: 0.5s;
    &:hover {
      //   @apply bg-brand_01-dark;
    }
  }

  .hamburger-inner {
    &:before,
    &:after,
    & {
      background-color: theme("colors.brand_01.default") !important;
      height: 4px;
    }
  }

  ul.menu {
  }

  &--transparent {
    background-color: rgba(255, 255, 255, 0.9);
    @apply py-xs;
    .logo img {
      width: 170px;
    }
    .header__contact {
      @apply py-xs;
    }
  }
}

.header-aside {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  overflow: auto;
  transition: 0.5s;
  pointer-events: none;
  max-height: 100vh;

  .element-animate {
    transition: none;
  }

  &--open {
    opacity: 1;
    pointer-events: inherit;
  }

  .hr {
    color: white;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  &__menu-lg {
    ul {
      li {
        padding: 1rem;
        a {
          @extend .title-xl;
          font-weight: bold;
          color: white;
          text-decoration: none;
        }
      }
    }
  }

  &__menu-sm {
    ul {
      li {
        padding: 0 1rem;

        a {
          text-decoration: none;
          color: white;
          @extend .text-lg;
        }
      }
    }
  }

  .swiper-slide {
    height: auto;
    padding: 5rem;
    @apply mb-md;

    @media screen and (max-width: 600px) {
      padding: 1rem;
      .title-lg,
      .title-lg b {
        font-size: 26px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
    }

    &__content {
      min-height: 7rem;
      h3,
      h3 * {
        @apply text-brand_01-dark;
        transition: 0.5s;
      }
    }
    a {
      text-decoration: none;
      img {
        opacity: 0.1;
        transition: 0.5s;
      }
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @apply bg-black;
        z-index: 1;
        opacity: 0;
        transition: 0.5s;
      }
      &:hover {
        &::before {
          opacity: 0.2;
        }
        img {
          opacity: 1;
        }
        h3,
        h3 * {
          @apply text-white;
          transform: translateX(10px);
        }
      }
    }
  }

  &__left {
    @media screen and(min-width: 1024px) {
      max-height: calc(100vh - 3rem);
      overflow: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
