.slider-posts--v1 {
  position: relative;
  width: 100%;
  .swiper-container {
    overflow: visible;
    // overflow: hidden;
    margin: 0;

    // @media (min-width: 1024px) {
    //   padding-left: calc((100vw - 1024px) / 2);
    // }

    // @media (min-width: 1280px) {
    //   padding-left: calc((100vw - 1240px) / 2);
    // }
  }
  .swiper-slide {
    width: auto !important;

    img {
      min-width: 30rem;
    }
  }

  .slider__nav {
    @apply flex items-end;
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
      left: inherit;
      right: inherit;
      top: inherit;
      background: #ffff;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      border: 1px solid theme("colors.gray.dark");
      @apply flex items-center justify-center ml-sm mb-sm;
    }
  }

  .swiper-pagination {
    width: 100%;
    position: initial;

    .swiper-pagination-bullet {
      margin: theme("spacing.xs");
      border-radius: unset;
      width: 60px;
      height: 4px;
      background: theme("colors.gray.default");

      &-active {
        background: theme("colors.text.1");
      }
    }
  }

  .parallax {
    position: relative;
    z-index: -1;
    .rellax {
      position: absolute;
      &:nth-child(1) {
        left: -17rem;
        top: -21rem;
        @screen md {
          left: -17rem;
          top: -27rem;
        }
      }
      &:nth-child(2) {
        right: -5rem;
        top: -10rem;
        @screen md {
          right: 17rem;
          top: -12rem;
        }
      }
    }
  } //parallax
}
