#map--v2 {
  .col-right {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    @screen lg {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }

  .col-left {
    overflow: hidden;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  .map {
    min-height: 480px;
    height: 100%;
    // box-shadow: 0px 1px 10px 4px rgba(0, 0, 0, 0.4);
    border-radius: 2rem;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    &__paginate-next,
    &__paginate-prev {
      &--disable {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    &__marker {
    }

    &__search-input {
    }

    &__number {
      top: 4px;
      left: 0;
      width: 100%;
      text-align: center;
      color: #fff;
      font-weight: 500;
    }
  }
}

.marker_label {
  margin-top: -6px !important;
}
