.single-post__content {
  @media screen and (max-width: 767px) {
    .container-content {
      padding: 0;
    }

    .single-post__hero {
      img {
        min-height: 250px;
        object-fit: cover;
        object-position: center;
      }

      .container-sm--hero {
        padding: 1rem;
        margin-bottom: 10px !important;

        .title-xl,
        .title-xl b {
          font-size: 26px;
          line-height: 30px;
        }
      }
    }
  }

  .single-post__info {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 50px;

    .single-post__cats,
    .single-post__data {
      @extend .text-md;

      a {
        text-decoration: none;
        font-weight: 100;
      }
    }

    color: white;
    h1 {
      b {
        color: white;
      }
    }
  }

  .single-post__hero {
    .single-post__background {
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 31.65%,
        rgba(0, 0, 0, 0.4) 31.65%,
        rgba(0, 0, 0, 0) 76.99%
      );
    }
  }
}
