.gallery {
  &--v6 {
    .gallery__images {
      display: grid;

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }

      grid-template-columns: 1.5fr 2fr;
      grid-gap: 2rem;

      @media screen and (max-width: 1023px) {
        grid-gap: 1rem;
      }

      & > figure:nth-child(1) {
        grid-column: 1 / 2;
        grid-row: 1 / 1;
      }

      & > figure:nth-child(2) {
        grid-column: 1 / 2;
        grid-row: 2 / 2;
      }

      & > figure:nth-child(3) {
        grid-column: 2 / 3;
        grid-row: 1 / 3;

        position: relative;
        top: 4rem;

        @media screen and (max-width: 1023px) {
          top: 0;
        }
      }
    }
  }
}
