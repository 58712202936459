.slider-gallery--v2 {
  .swiper-container {
    // overflow: visible;
    .swiper-wrapper {
      @apply items-center;
    }
  }
  .swiper-slide {
    // width: auto !important;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #ffff;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    border: 1px solid theme("colors.gray.dark");
    @apply flex items-center justify-center;
  }

  // .swiper-button-next,
  // .swiper-container-rtl .swiper-button-prev {
  // }

  // .swiper-button-prev,
  // .swiper-container-rtl .swiper-button-next {
  // }

  .swiper-pagination {
    width: 100%;
    position: initial;

    .swiper-pagination-bullet {
      margin: theme("spacing.xs");
      border-radius: unset;
      width: 60px;
      height: 4px;
      background: theme("colors.gray.default");

      &-active {
        background: theme("colors.text.1");
      }
    }
  }
}
