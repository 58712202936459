.slider-gallery-v1 {
  .swiper-container {
    // overflow: visible;
  }
  .swiper-slide {
    width: auto !important;
    height: 45rem;
    // width: 100%;

    img {
      width: auto;
      height: 100%;
    }

    @media screen and(max-width: 767px) {
      height: auto;
      img {
        height: auto;
        max-height: 20rem;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: relative;
    left: inherit;
    right: inherit;
    top: inherit;
    width: 3.5rem;
    height: 3.5rem;
    @apply flex items-center justify-center;
  }

  // .swiper-button-next,
  // .swiper-container-rtl .swiper-button-prev {
  // }

  // .swiper-button-prev,
  // .swiper-container-rtl .swiper-button-next {
  // }

  .swiper-pagination {
    width: 100%;
    position: initial;

    .swiper-pagination-bullet {
      margin: theme("spacing.xs");
      border-radius: unset;
      width: 60px;
      height: 4px;
      background: theme("colors.gray.default");

      &-active {
        background: theme("colors.text.1");
      }
    }
  }
}
