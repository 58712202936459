.slider--v2-multiple {
  .swiper-container {
    padding-bottom: 2rem;
    overflow: hidden;
  }

  &__category {
    position: relative;

    transition: all 0.6s;

    &--active,
    &:hover {
      @apply bg-gray text-white;
      // color: theme("colors.ui.7");
      &:before {
      }
    }
  }

  &__swiper {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: all 1s;
    z-index: 9;

    &--active {
      position: relative;
      opacity: 1;
      top: 0px;
      pointer-events: inherit;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
  }

  .swiper-button-prev {
    margin-right: -2px;
  }

  .swiper-button-disabled {
    // display: none;
  }

  .swiper-btns {
    height: 3.5rem;
  }
  .swiper-btn-container {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translateY(-120%);

    &--active {
      opacity: 1;
      pointer-events: all;
      position: inital;
    }
  }

  .parallax {
    position: relative;
    z-index: -1;
    .rellax {
      position: absolute;
      &:nth-child(1) {
        left: -11rem;
        z-index: 1;
        top: -3rem;
        @screen md {
          top: -11rem;
        }
      }
      &:nth-child(2) {
        left: -9rem;
        top: 7rem;
        @screen md {
          top: -5rem;
        }
      }
      &:nth-child(3) {
        right: 0rem;
        top: -9rem;
        @screen md {
          right: 10rem;
          top: -11rem;
        }
      }
      &:nth-child(4) {
        top: -6rem;
        right: -15rem;
        @screen md {
          right: 3rem;
          top: -9rem;
        }
      }
    }
  }
}
