.slider--v3 {
  // overflow: hidden;

  .swiper-slide {
    width: 100%;
    // max-height: 100%;

    img {
      width: 100%;
      // max-height: 100%;
    }
  }

  .swiper-buttons {
    position: initial;
    justify-content: flex-end;
  }

  .swiper-button-disabled {
    // display: none;
  }
}
