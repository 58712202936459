.button {
  &-v2 {
    @apply border-brand_01 border text-brand_01;
    transition: 0.5s;
    text-decoration: none;
    display: inline-block;
    a {
      text-decoration: none;
    }
    &--md {
      @extend .title-xs;
      @apply px-lg py-sm;
    }
    &:hover {
      @apply bg-brand_01 text-white border-brand_01;
    }
    &--disable {
      @apply border-gray border text-gray;
      &:hover {
        @apply bg-transparent text-gray border-gray;
        opacity: 0.7;
      }
    }

    &--underline {
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
  }
}
