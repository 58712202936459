/* Popup */
.o-popup {
  &--video {
    .o-popup__body {
      background: white;
      transition: all 0.5s;
      width: 100%;
      overflow: auto;
      max-height: 100%;

      width: auto;
      height: initial;

      &__inner {
        overflow-y: auto;
        height: 100%;
      }

      @screen lg {
        min-width: 920px;
        // min-height: 600px;
        max-width: 920px;
        max-height: 90%;
      }

      min-width: 95%;
      max-width: 95%;

      @media screen and (max-width: 767px) {
        max-height: 80%;

        .o-popup__image {
          max-width: 80%;
          margin: 0 auto;
        }
      }

      @media screen and (max-width: 600px) {
        max-width: 100%;
        width: 100%;
      }
    }

    .player-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      overflow: hidden;
      width: 100%;

      // @media screen and(max-width: 1400px) {
      //   padding-bottom: 40%; /* 16:9 */
      // }

      // @media screen and(max-width: 1024px) {
      //   padding-bottom: 56.25%; /* 16:9 */
      // }

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    &.o-popup--open {
      &:before {
        display: none;
      }
      .o-popup__body {
        top: 50%;
        pointer-events: all;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: all 0.3s;
      }
    }

    .o-popup__container {
      position: relative;
    }
    svg path {
      // fill: #fff;
    }

    .o-popup__close {
      z-index: 99999999999;
      border: none;

      svg {
        stroke-width: 2px;
      }
    }
  }

  textarea {
    height: 120px !important;
  }
}
