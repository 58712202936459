.slider--v1 {
  // overflow: hidden;

  .swiper-slide {
    width: 100%;
    // max-height: 100%;

    img {
      @media screen and (max-width: 767px) {
        width: 100%;
      } // width: 100%;
      // max-height: 100%;
    }
  }

  .swiper-buttons {
    position: initial;
    justify-content: flex-end;
  }

  .swiper-button-prev,
  .swiper-button-next {
  }

  .swiper-button-disabled {
    // display: none;
  }
}
