.gallery {
  &--v1 {
    display: flex;
    flex-wrap: wrap;
    figure {
      width: 50%;
      &:nth-child(3n-2) {
        width: 100%;
      }
      // @screen sm {
      //   width: calc(50% - 2rem);
      // }
      // @screen lg {
      //   width: calc(33.33% - 2rem);
      // }
      figcaption {
        display: none;
      }
      > a {
        display: block;
        position: relative;
        img {
          display: block;
          width: 100%;
        }
        .gallery__item__title {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding: 1.5rem;
          h2 {
            color: #fff;
          }
          svg {
            width: 5rem;
            height: 5rem; 
            min-width: 5rem;
          }
        }
      }
    }
  }
}


.pswp__caption__center {
  max-width: 1000px;
}