.button {
  &-v1 {
    @apply bg-brand_01;
    @apply text-white;
    transition: 0.5s;
    text-decoration: none;
    display: inline-block;
    a {
      text-decoration: none;
    }
    &--md {
      @extend .title-xs;
      @apply px-lg py-sm;
    }

    &--lg {
      @extend .title-xs;
      @apply px-lg py-md;
    }
    &:hover {
      @apply bg-brand_01-dark;
    }
  }
}
