/* Popup */
.o-popup {
  &--cookies {
    .o-popup__body {
      top: inherit;
      left: 0;
      bottom: 0;
      transform: inherit;
      max-width: 43rem;
    }
    &.o-popup--open {
      &:before {
        display: none;
      }
      .o-popup__body {
        pointer-events: all;
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
    .o-popup__container {
      position: relative;
    }
    svg path {
      fill: #fff;
    }
  }
}
