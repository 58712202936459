.multiple-gallery {
  .text-image__backplate {
    top: -2rem;
    left: 0;
    margin-left: -6rem;

    width: 100%;
    height: calc(100% + 6rem);
    z-index: -1;

    &--reverse {
      margin-left: initial;
      margin-left: 6rem;
    }
  }
}
