@import "./v1/head";
@import "./v2/head";
@import "./v3/head";

.breadcrumbs {
  @apply text-gray no-underline mb-sm;
  * {
    @extend .text-sm;
  }
  span a {
    @extend .text-sm;
    @apply text-gray no-underline;
    transition: 0.5s;
    &:hover {
      @apply text-black;
    }
  }
  .breadcrumb_last {
    @apply text-black;
  }
}
