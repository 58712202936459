.text-image {
  // text-image__backplate

  &__backplate {
    @apply bg-gray-light;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -6rem;

    width: 100%;
    height: calc(100% + 3rem);
    z-index: -1;

    &--reverse {
      margin-left: initial;
      margin-left: 6rem;
    }

    @media screen and (max-width: 1450px) {
      & {
        display: none !important;
      }
    }
  }
}
