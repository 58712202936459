.hero {
  &--v6 {
    // max-height: calc(100vh - 126px);

    .hero {
      &__scroll {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotate(90deg) translate(-46%, -400%);

        &-text {
          transform: rotate(180deg);
        }

        &-line {
          width: 100px;
          height: 2px;
          // background: theme('')
          margin-left: 10px;
        }
      }
    }

    .slider--v6 {
      .swiper-container {
        max-height: calc(100vh - 118px);

        img {
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}
