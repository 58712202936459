.slider-products-v1 {
  position: relative;
  width: 100%;
  .swiper-container {
    overflow: hidden;
    margin: 0;

    // @media (min-width: 1024px) {
    //   padding-left: calc((100vw - 1024px) / 2);
    // }

    // @media (min-width: 1280px) {
    //   padding-left: calc((100vw - 1240px) / 2);
    // }
  }
  .swiper-slide {
    @media screen and(max-width: 767px) {
      // width: auto !important;
      // min-width: 200px !important;
    }

    // width: 235px;
    // height: 350px;
    height: 40rem;

    // img {
    //   min-width: 30rem;
    // }
    &__content {
      min-height: 7rem;
      h3,
      h3 * {
        @apply text-black;
        transition: 0.5s;
      }
    }
    a {
      text-decoration: none;
      img {
        opacity: 0.1;
        transition: 0.5s;
      }
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @apply bg-black;
        z-index: 1;
        opacity: 0;
        transition: 0.5s;
      }
      &:hover {
        &::before {
          opacity: 0.2;
        }
        img {
          opacity: 1;
        }
        h3,
        h3 * {
          @apply text-white;
          transform: translateX(10px);
        }
      }
    }
  }

  .slider__nav {
    @apply flex items-end;
    .swiper-button-next {
      position: relative;
      left: inherit;
      right: inherit;
      top: inherit;
      height: 3.5rem;
      @apply flex items-center justify-center ml-sm mb-sm;
    }
  }

  // .swiper-pagination {
  //   width: 100%;
  //   position: initial;

  //   .swiper-pagination-bullet {
  //     margin: theme("spacing.xs");
  //     border-radius: unset;
  //     width: 60px;
  //     height: 4px;
  //     background: theme("colors.gray.default");

  //     &-active {
  //       background: theme("colors.text.1");
  //     }
  //   }
  // }
}
