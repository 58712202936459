.form-v1 {
  .form__head {
    position: relative;
    @apply px-md;
    @apply py-sm;
    svg {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .form__body {
    @apply px-md;
    @apply pb-md;
  }
}

.wpcf7 {
  @apply mt-md;
  form {
    > span,
    > div {
      @apply mb-md block;
    }
  }
  input[type="url"],
  input[type="email"],
  input[type="tel"],
  input[type="text"],
  input[type="phone"],
  textarea,
  select {
    border-radius: 0px;
    @apply border-black text-black;
    // @extend .text-sm;
  }
  input[type="checkbox"] {
    border-radius: 0px;
  }
  .wpcf7-list-item {
    margin: 0;
  }
  .wpcf7-acceptance {
    .wpcf7-list-item-label {
      // @extend .text-sm;
    }
  }
  .wpcf7-submit {
    @extend .button-v1;
    @extend .button-v1--md;
    border-radius: 0;
    width: inherit;
    @apply ml-auto block mt-lg;
    &:disabled {
      opacity: 0.4;
    }
  }
  .form__send {
    position: relative;
    span.wpcf7-spinner {
      position: absolute;
      left: 0;
      bottom: 18px;
    }
  }
}
