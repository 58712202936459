.head-v2 {
  .head__image {
    img {
      @media screen and(min-width: 1200px) {
        height: calc(100vh - 121px);
      }

      min-height: 30rem;
    }
  }
}
