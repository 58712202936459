
#body {
    .wpforms-form {
        input, textarea {
            border-radius: 0px;
            @apply bg-gray-light border-0 text-black;
        }
        button[type=submit] {
            // @apply bg-blue text-white uppercase border-0 px-lg py-md;
            @extend .title-sm;
            transition: .5s;
            &:hover {
                // @apply bg-blue-opacity;
            }
        }
        ::placeholder {
            @apply text-gray;
        }
        .wpforms-field {
            &.wpforms-one-half {
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    } 
}

// .wpforms-field {
//     padding: 0 !important;
//     padding-bottom: 10px !important;
//   }
  
//   .wpforms-one-half {
//     width: 49% !important;
//   }
//   .wpforms-one-half:not(.wpforms-first) {
//     margin-left: 2% !important;
//   }
  
//   div.wpforms-container-full .wpforms-form ul,
//   div.wpforms-container-full .wpforms-form ul li {
//     margin-top: 1rem !important;
//     li {
//       display: flex;
//       align-items: center;
  
//       a {
//         text-decoration: underline;
//       }
//     }
//   }
  
//   label {
//     font-family: Montserrat !important;
//     font-style: normal !important;
//     font-weight: normal !important;
//     font-size: 14px !important;
//     letter-spacing: 0.1em !important;
//   }
  
//   input[type="checkbox"] {
//     margin-left: 0 !important;
//     border: 1px solid theme("colors.gray.default") !important;
  
//     &::before {
//       color: theme("colors.gray.dark") !important;
//     }
//   }
  
//   input[type="text"],
//   input[type="email"],
//   textarea {
//     padding: 10px 15px !important;
//     width: 100% !important;
//     border: 1px solid theme("colors.gray.default") !important;
  
//     font-family: Montserrat !important;
//     font-style: normal !important;
//     font-weight: normal !important;
//     font-size: 14px !important;
//     color: theme("colors.gray.dark") !important;
//     border-radius: 0 !important;
//   }
  
//   .wpforms-field-required {
//     font-family: Montserrat !important;
//     font-style: normal !important;
//     font-weight: normal !important;
//     font-size: 14px !important;
//     color: theme("colors.gray.dark") !important;
//     border-radius: 0 !important;
//   }
  
//   .wpforms-submit {
//     border: none !important;
//     color: white !important;
//     background: theme("colors.gray.default") !important;
//     text-transform: uppercase !important;
//     position: relative !important;
//     padding: 8px 16px !important;
//     display: flex !important;
//     align-items: center !important;
//     border: 1px solid transparent !important;
  
//     font-family: Montserrat !important;
//     font-style: normal !important;
//     font-weight: bold !important;
//     font-size: 1.4rem !important;
//     z-index: 1;
//     transition: all 0.6s;
  
  
//     &:hover {
//       background: white !important;
//       color: theme("colors.gray.default") !important;
//       border: 1px solid theme("colors.gray.default") !important;
  
//     }
//   }