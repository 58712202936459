.showroom-gallery-v1 {
  .showroom-gallery-inner {
    &__disable {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }
  }
}
