.card-post-v1 {
  .card-post {
    &__txt {
      .title-md {
        b {
          color: theme("colors.brand_01.dark");
        }
      }
    }
    &__info {
      a {
        @extend .text-sm;
      }
    }
  }
  a {
    text-decoration: none;
  }
}
