$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 40px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 10px;
$hamburger-layer-color: theme("colors.gray.default");
$hamburger-layer-border-radius: 2px;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: theme("colors.gray.default");
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

@import "../../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

// @import "./v5/header";
@import "./v1/header";

.hamburger-box {
  display: flex;
}
