.slider-hero-v1 {
  width: 100%;

  & img {
    @media screen and(min-width: 1200px) {
      height: calc(100vh - 230px);
    }
    // height: 100% !important;
  }

  .swiper-container {
    // overflow: visible;
  }
  .swiper-slide {
    // width: auto !important;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    transform: translateY(-50%);
    // width: 3.5rem;
    // height: 3.5rem;
    // border-radius: 50%;
    @apply flex items-center justify-center;

    background-color: rgba(255, 255, 255, 0.6);
  }

  // .swiper-button-next,
  // .swiper-container-rtl .swiper-button-prev {
  // }

  // .swiper-button-prev,
  // .swiper-container-rtl .swiper-button-next {
  // }

  .swiper-pagination {
    width: 100%;
    position: initial;

    .swiper-pagination-bullet {
      margin: theme("spacing.xs");
      border-radius: unset;
      width: 60px;
      height: 4px;
      background: theme("colors.gray.default");

      &-active {
        background: theme("colors.text.1");
      }
    }
  }

  .arrow-down {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    z-index: 50;
  }
}
