.hero {
  &--v1 {
    @apply relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: block;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      z-index: 1;
    }
    hr {
      z-index: 9;
      position: relative;
    }
    .hero {
      &__image {
        @apply bg-cover;
        position: absolute;
        background-position: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &__title {
        @extend .title-xl;
        @apply uppercase;
      }

      &__text {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        z-index: 2;
        @apply p-md;
        @screen md {
          @apply p-xl;
        }
        h3,
        p {
          width: 100%;
        }
      }
    }
    &.color-rosso {
      .button {
        background-color: theme("colors.red.default");
        color: #fff;
        &:before {
          background-color: theme("colors.red.hover");
          opacity: 1;
        }
      }
      hr {
        color: theme("colors.red.default");
      }
    }
  }
}
