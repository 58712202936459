@charset 'UTF-8';

// 1. Configuration and helpers
@import "abstracts/variables", "abstracts/functions", "abstracts/mixins";

// 3. Base stuff
@import "base/base", "base/fonts", "base/typography";
// "base/woocommerce", "base/woocommerce/account";

// Plugin
@import // "plugins/variation-swateches",
  // "plugins/caldera-form",
  // "plugins/wp-google-maps",
  "plugins/wpforms";

// 6. Page-specific styles
@import "pages/home", "pages/archive-posts", "pages/single-post",
  "pages/contatti", "pages/single-portfolio", "pages/content-jobs";

// // 7. Themes
// @import "themes/default", "themes/professionisti";

/** Components */
@import "../../partials/components/globals";
@import "../../partials/components/sliders";
@import "../../partials/components/hero-banners";
@import "../../partials/components/galleries/";
@import "../../partials/components/inputs";
@import "../../partials/components/blocks/";
@import "../../partials/components/maps";
@import "../../partials/components/popups/";
@import "../../partials/components/text-image";
@import "../../partials/components/text-video";

// @import "../../partials/components/accordions";
@import "../../partials/components/cards";
@import "../../partials/components/forms/v1/form";
// @import "../../partials/components/delivery-info/";
@import "../../partials/components/heads";
// @import "../../partials/components/payments-method";
// @import "../../partials/components/timelines";
// @import "../../partials/components/videos";
// @import "../../partials/components/presentation/";
// @import "../../partials/components/multistep-attribute/";
@import "../../partials/components/paginations/";
@import "../../partials/components/tabs/gallery";
@import "../../partials/components/tabs/maps/";
@import "../../partials/components/tabs/files/";

@import "../../partials/components/cta/";

// @import "../../partials/components/newsletter/";
@import "../../partials/components/sidebar/sidebar";

/** Vendors*/
@import "../../src/sass/vendors/locomotive-scroll";

/** Load Page Animation */
@import "../../src/js/lazy/LoadAnimate/load-animate";

.no-scroll {
  overflow-y: hidden;
}
.hr {
  width: 3rem;
  border-width: 1px;
}

@media screen and(max-width: 767px) {
  iframe.grayscale {
    max-height: 350px;
  }
}

.container {
  width: 100%;
  max-width: 1280px;
}

.container-xs {
  @media screen and(max-width: 767px) {
    padding: 0 1rem;
  }
}

.no-padding {
  padding: 0;
}

.backplate {
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.6s ease-out;
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 99000;
  &--open {
    pointer-events: all;
    transition: opacity 0.3s ease-out;
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .space-xxl {
    height: 6rem;
  }

  .space-3xl {
    height: 8rem;
  }
}

// Animation
.element-animate {
  opacity: 0;
  transform: translateY(50px);
  transition: all 1.2s ease-out;

  &--done {
    opacity: 1;
    transform: translateY(0px);
  }
}

.cover {
  content: "";
  position: absolute;
  top: 0;
  left: initial;
  right: 0;
  background: theme("colors.gray.default");
  width: 0%;
  height: 100%;
  z-index: 9;
  // transition: all 0.5s;

  &--animate {
    animation-name: cover;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
}

@keyframes cover {
  0% {
    left: 0;
    right: initial;
    width: 0%;
  }

  59% {
    left: 0;
    right: initial;
    width: 100%;
  }

  60% {
    left: initial;
    right: 0;
    width: 100%;
  }

  100% {
    left: initial;
    right: 0;
    width: 0%;
  }
}
// End Animation

// a {
//   transition: all 0.5s;
//   &:hover {
//     color: theme("colors.yellow.default");
//     // opacity: 0.7;
//   }
// }
