// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

// @font-face {
//   font-family: "Graphik";
//   src: url("../../resources/font/Graphik-Bold.woff2") format("woff2"),
//     url("../../resources/font/Graphik-Bold.woff") format("woff");
//   font-weight: bold;
//   font-style: normal;
// }

// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

@font-face {
  font-family: "Futura";
  src: url("../../font/Futura-Bold.woff2") format("woff2"),
    url("../../font/Futura-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../../font/Futura-Medium.woff2") format("woff2"),
    url("../../font/Futura-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../../font/Futura-MediumItalic.woff2") format("woff2"),
    url("../../font/Futura-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

// @font-face {
//   font-family: "Futura";
//   src: url("../../font/FuturaStd-Book.woff2") format("woff2"),
//     url("../../font/FuturaStd-Book.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {font-family: "Futura"; 
  src: url("//db.onlinewebfonts.com/t/35e5d1a7aa6da471de4cfb4a47ebaca8.eot"); 
  src: url("//db.onlinewebfonts.com/t/35e5d1a7aa6da471de4cfb4a47ebaca8.eot?#iefix") format("embedded-opentype"), 
  url("//db.onlinewebfonts.com/t/35e5d1a7aa6da471de4cfb4a47ebaca8.woff2") format("woff2"), 
  url("//db.onlinewebfonts.com/t/35e5d1a7aa6da471de4cfb4a47ebaca8.woff") format("woff"), 
  url("//db.onlinewebfonts.com/t/35e5d1a7aa6da471de4cfb4a47ebaca8.ttf") format("truetype"), 
  url("//db.onlinewebfonts.com/t/35e5d1a7aa6da471de4cfb4a47ebaca8.svg#Futura Std Book") format("svg"); 
  font-weight: 300;
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: "Futura";
  src: url("../../font/FuturaStd-BookOblique.woff2") format("woff2"),
    url("../../font/FuturaStd-BookOblique.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

