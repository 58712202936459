.slider--v6 {
  // overflow: hidden;

  .swiper-slide {
    width: 100%;
    // max-height: 100%;

    img {
      width: 100%;
      // max-height: 100%;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    bottom: 5%;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
  }

  .swiper-button-disabled {
    // display: none;
  }
}
